<template>
  <div class="container px-0">
    <header>
      <h4 class="text-center my-4">Światła zewnętrzne</h4>
    </header>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="garaz" pin="23" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="garaz" pin="25" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="36" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="37" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="38" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="39" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="40" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="5" />
      </div>
    </div>

    <header>
      <h4 class="text-center my-4 mt-5">Światła wewnętrzne</h4>
    </header>

    <div class="mb-5 row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="44" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="45" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="41" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="43" />
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <SwitchBox driver="swiatlo" pin="2" />
      </div>
    </div>

    <header>
      <h4 class="text-center my-4 mt-5">Ustawienia</h4>
    </header>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <div class="box">
          <div class="header">Tryb czujek</div>
          <FunctionBtn
            driver="swiatlo"
            func="trybczujek"
            :autoload="true"
            size="sm"
            class="my-2 mx-auto"
          />
        </div>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4">
        <div class="box">
          <div class="header">Dzwonek</div>
          <FunctionBtn
            driver="swiatlo"
            func="dzwonek"
            :autoload="true"
            size="sm"
            class="my-2 mx-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  components: {
   
  }
}
</script>
