var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "garaz",
      "pin": "23"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "garaz",
      "pin": "25"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "36"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "37"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "38"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "39"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "40"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "5"
    }
  })], 1)]), _vm._m(1), _c('div', {
    staticClass: "mb-5 row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "44"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "45"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "41"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "43"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('SwitchBox', {
    attrs: {
      "driver": "swiatlo",
      "pin": "2"
    }
  })], 1)]), _vm._m(2), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Tryb czujek")]), _c('FunctionBtn', {
    staticClass: "my-2 mx-auto",
    attrs: {
      "driver": "swiatlo",
      "func": "trybczujek",
      "autoload": true,
      "size": "sm"
    }
  })], 1)]), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v("Dzwonek")]), _c('FunctionBtn', {
    staticClass: "my-2 mx-auto",
    attrs: {
      "driver": "swiatlo",
      "func": "dzwonek",
      "autoload": true,
      "size": "sm"
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Światła zewnętrzne")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4 mt-5"
  }, [_vm._v("Światła wewnętrzne")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4 mt-5"
  }, [_vm._v("Ustawienia")])]);

}]

export { render, staticRenderFns }